import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { startsWith } from 'lodash';

import PhoneInput from 'react-phone-input-2';
import { PaymentElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';

import Dropdown from './Dropdown';
import FieldValidationErrorMsg from './FieldValidationErrorMsg';

import { stages } from '../constants/stages';
// import { COURSES_DURATION_AND_PRICES } from '../utils/constants';

import IconArrowRight from '../assets/images/svg/arrow-right.inline.svg';
import { COURSES_DURATION_AND_PRICES, COURSE_FEE, FIRST_SET_OF_BOOKS } from '../utils/constants';
import 'react-phone-input-2/lib/style.css';

const esToEnTranslationMap = {
  Hombre: 'Male',
  Mujer: 'Female',
  'Preferir no decirlo': 'Prefer not to say',
  'Piso compartido': 'Shared flat',
  Residencia: 'Student residence',
  Español: 'Spanish',
  Inglés: 'English',
  Si: 'Yes',
  No: 'No',
  '1 mes': '1 month',
  '2 meses': '2 months',
  '3 meses': '3 months',
};

function ApplyFormComponent({
  hasErrorsOnFirstStage,
  hasErrorsOnSecondStage,
  isLoading,
  isSubmiting,
  isError,
  handleNextStage,
  handlePrevStage,
  handleChange,
  setFieldValue,
  values,
  courseDurationOptions,
  startDateOptions,
  levelsOptions,
  coursesOptions,
  handleSubmit,
  stage,
  touched,
  setTouched,
  errors,
  initialValues,
  stripe,
  clientSecret,
  airportPickUpPrice,
  accommodationPrice,
  totalPrice,
  paymentType,
  applyDeposit,
  applyFullPayment,

}) {
  const [isPhoneValid, setIsPhoneValid] = useState();
  return (
    <form className="forms" onSubmit={handleSubmit}>
      {stage === stages.COURSE && (
        <div className="form">
          <h1 className="c-title-22 form__title">The Course</h1>
          <h3 className="form__subtitle">Choose a course*</h3>
          <div className="dropdown-container">
            <Dropdown
              value={{ value: values.course, label: values.course }}
              onChange={({ value }) => {
                handleChange({ target: { name: 'course', value } });
                handleChange({
                  target: { name: 'startDate', value: initialValues.startDate },
                });
                handleChange({
                  target: { name: 'courseDuration', value: initialValues.courseDuration },
                });
              }}
              options={coursesOptions}
              placeholder="Select your preferred course..."
            />
          </div>
          <FieldValidationErrorMsg field={errors.course && touched.course} />
          <h3 className="form__subtitle start-date">Level*</h3>
          <div className="dropdown-container">
            <Dropdown
              value={{ value: values.level, label: values.level }}
              onChange={({ value }) => handleChange({ target: { name: 'level', value } })}
              options={levelsOptions}
              placeholder="Select your level..."
            />
            <p className="notice">
              Not sure of your level? Complete our{' '}
              <a
                className="red-link"
                target="_blank"
                rel="noopener noreferrer"
                href="https://form.jotform.com/231496158857369"
              >
                Spanish level test.{' '}
              </a>{' '}
            </p>
          </div>
          <FieldValidationErrorMsg field={errors.level && touched.level} />

          <h3 className="form__subtitle start-date">Start Date* </h3>
          <div className="dropdown-container">
            <Dropdown
              value={{ value: values.startDate, label: values.startDate }}
              isDisabled={values.course?.length === 0}
              onChange={({ value }) => handleChange({ target: { name: 'startDate', value } })}
              options={startDateOptions}
              placeholder="Select your start date..."
            />
          </div>
          <FieldValidationErrorMsg field={errors.startDate && touched.startDate} />

          <h3 className="form__subtitle start-date">Duration* </h3>
          <div className="dropdown-container">
            <Dropdown
              value={{ value: values.courseDuration, label: values.courseDuration }}
              isDisabled={values.startDate === initialValues.startDate}
              onChange={({ value }) => handleChange({ target: { name: 'courseDuration', value } })}
              options={courseDurationOptions}
              placeholder="Select your start date..."
            />
          </div>
          <FieldValidationErrorMsg field={errors.courseDuration && touched.courseDuration} />

          <div>
            <h2 className="c-title-22 form__title">Additional Services</h2>
            <h3 className="form__subtitle start-date">
              Accommodation* <span className="accent">(Minimum stay 1 month)</span>{' '}
            </h3>
            <div className="form__radio">
              <input
                id="course-radio-1"
                type="radio"
                name="accommodation"
                checked={values.accommodation === 'Piso compartido'}
                value="Piso compartido"
                onChange={(e) => {
                  handleChange(e);
                  handleChange({ target: { name: 'accommodationDuration', value: '' } });
                }}
              />
              <label htmlFor="course-radio-1">
                <span className="label">Shared flat</span>
              </label>
            </div>
            <div className="form__radio">
              <input
                id="course-radio-2"
                type="radio"
                name="accommodation"
                checked={values.accommodation === 'Residencia'}
                value="Residencia"
                onChange={(e) => {
                  handleChange(e);
                  handleChange({ target: { name: 'accommodationDuration', value: '' } });
                }}
              />
              <label htmlFor="course-radio-2">
                <span className="label">Student residence</span>
              </label>
            </div>
            <div className="form__radio">
              <input
                id="course-radio-3"
                type="radio"
                name="accommodation"
                checked={values.accommodation === 'No'}
                value="No"
                onChange={(e) => {
                  handleChange(e);
                  handleChange({ target: { name: 'accommodationDuration', value: 'No' } });
                }}
              />
              <label htmlFor="course-radio-3">
                <span className="label">No</span>
              </label>
            </div>
          </div>
          <FieldValidationErrorMsg field={errors.accommodation && touched.accommodation} />

          <AccommodationDuration
            accommodationDuration={values.accommodationDuration}
            accommodation={values.accommodation}
            handleChange={handleChange}
            touched={touched}
            errors={errors}
          />
          <div>
            <h3 className="form__subtitle start-date">Airport pick up*</h3>
            <div className="form__radio">
              <input
                id="course-radio-airportPickUp"
                type="radio"
                name="airportPickUp"
                checked={values.airportPickUp === 'Si'}
                value="Si"
                onChange={handleChange}
              />
              <label htmlFor="course-radio-airportPickUp">
                <span className="label">Yes 70€</span>
              </label>
            </div>
            <div className="form__radio">
              <input
                id="course-radio-airportPickUp-2"
                type="radio"
                name="airportPickUp"
                value="No"
                checked={values.airportPickUp === 'No'}
                onChange={handleChange}
              />
              <label htmlFor="course-radio-airportPickUp-2">
                <span className="label">No</span>
              </label>
            </div>
          </div>

          {/* <h3 className="form__subtitle form__extension-course">
        Would you like an extension course to apply for a student visa?
      </h3>
      <div className="form__checkbox">
        <input
          id="extension-courses-checkbox-1"
          type="checkbox"
          value={true}
          name="developingTeacher"
          // ref={register}
        />
        <label htmlFor="extension-courses-checkbox-1">
          <span className="label">Developing Teacher</span>
        </label>
      </div>
      <div className="form__checkbox">
        <input
          id="extension-courses-checkbox-2"
          type="checkbox"
          value={true}
          name="developingTeacherAndSpanish"
          // ref={register}
        />
        <label htmlFor="extension-courses-checkbox-2">
          <span className="label">Developing Teacher & Spanish</span>
        </label>
      </div>
      <div className="form__checkbox">
        <input
          id="extension-courses-checkbox-3"
          type="checkbox"
          value={true}
          name="notSure"
          // ref={register}
        />
        <label htmlFor="extension-courses-checkbox-3">
          <span className="label">Not sure</span>
        </label>
      </div> */}
          {hasErrorsOnFirstStage && (
            <div className="error-msg">
              <span>Some fields are missing or contain errors.</span>
            </div>
          )}
          <button
            disabled={hasErrorsOnFirstStage}
            onClick={handleNextStage}
            className="c-btn c-btn--red"
            type="button"
          >
            Continue
          </button>
        </div>
      )}

      {stage === stages.ABOUT && (
        <div className="form">
          <BackButton onClick={handlePrevStage} />
          <h1 className="c-title-22 form__title">About you</h1>
          <label className="form__first-name">
            <p className="c-text-14 text-under">First Name*</p>
            <input
              className="input__first-name"
              type="text"
              placeholder="John"
              name="firstName"
              onChange={handleChange}
              value={values.firstName}
            />
            <FieldValidationErrorMsg field={errors.firstName && touched.firstName} />
          </label>
          <label className="form__last-name">
            <p className="c-text-14 text-under">Last Name*</p>
            <input
              className="input__last-name"
              type="text"
              placeholder="Smith"
              name="lastName"
              onChange={handleChange}
              value={values.lastName}
            />
            <FieldValidationErrorMsg field={errors.lastName && touched.lastName} />
          </label>
          <label className="form__first-name">
            <p className="c-text-14 text-under">Email address*</p>
            <input
              className="input__first-name"
              type="email"
              placeholder="johnsmith@gmail.com"
              name="email"
              onChange={handleChange}
              value={values.email}
            />
            <FieldValidationErrorMsg field={errors.email && touched.email} />
          </label>
          <label className="form__last-name phone-number">
            <p className="c-text-14 text-under">Phone Number</p>
            {/* <input
              className="input__last-name"
              type="tel"
              placeholder="+34 7841-15-58"
              name="phone"
              onChange={handleChange}
              value={values.phone}
            /> */}
            <PhoneInput
              className="input__last-name"
              placeholder="+XX XXX XXX XXX"
              value={values.phone}
              onChange={(value) => setFieldValue('phone', value)}
              onBlur={() => setTouched('phone', true)}
              isValid={(inputNumber, country, countries) => {
                const checkValidity = countries.some((country) => {
                  const checkValidity =
                    startsWith(inputNumber, country.dialCode) ||
                    startsWith(country.dialCode, inputNumber);

                  return checkValidity;
                });
                setIsPhoneValid(checkValidity);

                return checkValidity;
              }}
            />
            <FieldValidationErrorMsg field={errors.phone && touched.phone} />
          </label>
          <label className="form__email">
            <p className="c-text-14 text-under">Your current location*</p>
            <input
              className="input__last-name"
              type="text"
              placeholder="e.g. London, UK"
              name="userLocation"
              onChange={handleChange}
              value={values.userLocation}
            />
            <FieldValidationErrorMsg field={errors.userLocation && touched.userLocation} />
          </label>
          {/* <h3 className="form__subtitle start-date">Nationality*</h3>
          <Dropdown
            value={{ value: values.nationality, label: values.nationality }}
            onChange={({ value }) => handleChange({ target: { name: 'nationality', value } })}
            options={countryNamesOptions}
            placeholder="Select your country"
            isSearchable
          />
          <FieldValidationErrorMsg field={errors.nationality && touched.nationality} /> */}
          <div className="date">
            <h3 className="form__subtitle">Date of birth*</h3>
            <div className="date__wrapper">
              <input
                type="number"
                placeholder="01"
                className="input__date input__date--dd"
                name="userDobDD"
                value={values.userDobDD}
                onChange={handleChange}
              />
              <span className="text-fw-600">DD</span>
            </div>
            <div className="date__wrapper">
              <input
                type="number"
                placeholder="02"
                className="input__date input__date--mm"
                name="userDobMM"
                value={values.userDobMM}
                onChange={handleChange}
              />
              <span className="text-fw-600">MM</span>
            </div>
            <div className="date__wrapper">
              <input
                type="number"
                placeholder="1987"
                className="input__date input__date--yyyy"
                name="userDobYYYY"
                value={values.userDobYYYY}
                onChange={handleChange}
              />
              <span className="text-fw-600">YYYY</span>
            </div>
            <FieldValidationErrorMsg
              field={
                (errors.userDobDD && touched.userDobDD) ||
                (errors.userDobMM && touched.userDobMM) ||
                (errors.userDobYYYY && touched.userDobYYYY)
              }
            />
          </div>
          <h3 className="form__subtitle">Gender*</h3>
          <div>
            <div className="form__radio">
              <input
                id="gender-radio-1"
                type="radio"
                value="Hombre"
                name="gender"
                onChange={handleChange}
                checked={values.gender === 'Hombre'}
              />
              <label htmlFor="gender-radio-1">
                <span className="label">Male</span>
              </label>
            </div>

            <div className="form__radio">
              <input
                id="gender-radio-2"
                type="radio"
                value="Mujer"
                name="gender"
                onChange={handleChange}
                checked={values.gender === 'Mujer'}
              />
              <label htmlFor="gender-radio-2">
                <span className="label">Female</span>
              </label>
            </div>
            <div className="form__radio">
              <input
                id="gender-radio-3"
                type="radio"
                value="Prefiere no decir"
                name="gender"
                onChange={handleChange}
                checked={values.gender === 'Prefiere no decir'}
              />
              <label htmlFor="gender-radio-3">
                <span className="label">Would rather not say</span>
              </label>
            </div>
            <FieldValidationErrorMsg field={errors.gender && touched.gender} />
          </div>

          <h3 className="form__subtitle">Preferred contact language*</h3>
          <div>
            <div className="form__radio">
              <input
                id="english-speaker-radio-1"
                type="radio"
                value="Inglés"
                name="preferredContactLanguage"
                onChange={handleChange}
                checked={values.preferredContactLanguage === 'Inglés'}
              />
              <label htmlFor="english-speaker-radio-1">
                <span className="label">English</span>
              </label>
            </div>

            <div className="form__radio">
              <input
                id="english-speaker-radio-2"
                type="radio"
                value="Español"
                name="preferredContactLanguage"
                onChange={handleChange}
                checked={values.preferredContactLanguage === 'Español'}
              />
              <label htmlFor="english-speaker-radio-2">
                <span className="label">Spanish</span>
              </label>
            </div>
            <FieldValidationErrorMsg
              field={errors.preferredContactLanguage && touched.preferredContactLanguage}
            />
          </div>

          <label className="form__first-name">
            <p className="c-text-14 text-under">Emergency contact name</p>
            <input
              className="input__first-name"
              placeholder="Smith"
              type="text"
              name="emergencyContactName"
              onChange={handleChange}
              value={values.emergencyContactName}
            />
            <FieldValidationErrorMsg
              field={errors.emergencyContactName && touched.emergencyContactName}
            />
          </label>
          <label className="form__last-name">
            <p className="c-text-14 text-under">Emergency contact telephone</p>
            <input
              className="input__last-name"
              type="text"
              placeholder="+34..."
              name="emergencyContactPhone"
              onChange={handleChange}
              value={values.emergencyContactPhone}
            />
            <FieldValidationErrorMsg field={errors.lastName && touched.lastName} />
          </label>

          <label className="form__textarea">
            <p className="c-text-14 text-under">Comments / Questions</p>
            <textarea
              className="input__textarea input__textarea--sm"
              placeholder="Any comments or questions? Write here."
              name="comments"
              value={values.comments}
              onChange={handleChange}
            />
          </label>
          {hasErrorsOnSecondStage && (
            <div className="error-msg">
              <span>Some fields are missing or contain errors.</span>
            </div>
          )}
          {/* <button
            onClick={handleNextStage}
            disabled={hasErrorsOnSecondStage}
            className="c-btn c-btn--red"
            type="button"
          >
            Continue
          </button> */}
          <button
            type="submit"
            // onClick={handleNextStage}
            className="submit c-btn c-btn--red mt-4"
            disabled={hasErrorsOnFirstStage || hasErrorsOnSecondStage || isSubmiting || isLoading}
          >
            {isSubmiting ? <i className="spinner" /> : 'See Price & Summary'}
          </button>
        </div>
      )}

      {stage === stages.SUMMARY && (
        <div className="form">
          <BackButton onClick={handlePrevStage} />

          <h1 className="c-title-22 form__title">Summary and Price</h1>
          <div className="summary-block">
            <h3 className="form__subtitle">Course & Accommodation</h3>
            <p className="form__text">
              Course: <b className="accent">{values.course}</b>
            </p>
            <p className="form__text">
              Level: <b className="accent">{values.level}</b>
            </p>
            <p className="form__text">
              Duration: <b className="accent">{values.courseDuration}</b>
            </p>
            <p className="form__text">
              Accommodation: <b className="accent">{esToEnTranslationMap[values.accommodation]}</b>
            </p>
            <p className="form__text">
              Duration:{' '}
              <b className="accent">{esToEnTranslationMap[values.accommodationDuration]}.</b>{' '}
              <strong>Note: we will contact you to confirm the arrival date and time.</strong>
            </p>
            <p className="form__text">
              Taxi: <b className="accent">{esToEnTranslationMap[values.airportPickUp]}</b>
            </p>
          </div>
          <div className="summary-block">
            <h3 className="form__subtitle">Contact</h3>
            <p className="form__text">
              First name: <b className="accent">{values.firstName}</b>
            </p>
            <p className="form__text">
              Last name: <b className="accent">{values.lastName}</b>
            </p>
            <p className="form__text">
              Email address: <b className="accent">{values.email}</b>
            </p>
            <p className="form__text">
              Phone Number: <b className="accent">{values.phone}</b>
            </p>
            <p className="form__text">
              Current location: <b className="accent">{values.userLocation}</b>
            </p>
            <p className="form__text">
              Nationality: <b className="accent">{values.nationality}</b>
            </p>
            <p className="form__text">
              Date of birth:{' '}
              <b className="accent">{`${values.userDobDD}/${values.userDobMM}/${values.userDobYYYY}`}</b>
            </p>
            <p className="form__text">
              Gender: <b className="accent">{esToEnTranslationMap[values.gender]}</b>
            </p>
            <p className="form__text">
              Preferred contact language:{' '}
              <b className="accent">{esToEnTranslationMap[values.preferredContactLanguage]}</b>
            </p>
            <p className="form__text">
              Emergency contact name: <b className="accent">{values.emergencyContactName}</b>
            </p>
            <p className="form__text">
              Emergency contact telephone: <b className="accent">{values.emergencyContactPhone}</b>
            </p>
          </div>
          <div className="summary-block">
            <h3 className="form__subtitle">Price</h3>
            <p>
              Course fee:{' '}
              <b className="accent">
                {COURSES_DURATION_AND_PRICES?.[values?.course]?.[values?.courseDuration] ?? 0}€
              </b>
            </p>
            <p className="form__text">
              1st set of books: <b className="accent">{FIRST_SET_OF_BOOKS}€</b>
            </p>
            {Boolean(accommodationPrice) && (
              <p className="form__text">
                Accommodation: <b className="accent">{accommodationPrice}€</b>
              </p>
            )}
            {Boolean(airportPickUpPrice) && (
              <p className="form__text">
                Taxi: <b className="accent">{airportPickUpPrice}€</b>
              </p>
            )}
          </div>

          <div className="form__radio">
            <input
              id="course-radio-1"
              type="radio"
              name="deposit"
              checked={paymentType.appliedDeposit === false}
              onChange={applyFullPayment}
            />
            <label htmlFor="course-radio-1">
              <span className="label">Pay full price</span>
            </label>
          </div>
          <div className="form__radio">
            <input
              id="course-radio-2"
              type="radio"
              name="deposit"
              checked={paymentType.appliedDeposit === true}
              onChange={applyDeposit}
            />
            <label htmlFor="course-radio-2">
              <span className="label ">Pay a 350€ deposit to book your place </span>
            </label>
          </div>

          <h3 className="form__subtitle">Total: {totalPrice}€</h3>

          {/* <h4 className="form__total mb-4">Total: {totalPrice}€</h4> */}
          {/* <p className="deposit accent">Pay a 350€ deposit to book your place </p> */}
          {/* <label className="form__first-name promotional-code">
          <p className="c-text-14 text-under">Promotional code</p>
          <input
            className="input__first-name"
            type="text"
            placeholder="Enter code"
            name="promotionalCode"
            // ref={register}
          />
          <button className="promotional-code__btn">Apply Promotional Code</button>
        </label> */}

          {/* <h3 className="form__subtitle">Terms and conditions*</h3>
        <div className="form__checkbox terms">
          <input
            id="agree-checkbox-1"
            type="checkbox"
            value={true}
            name="agreeRules"
          />
          <label htmlFor="agree-checkbox-1">
            <span className="label">
              I agree to TEFL Iberia’s{' '}
              <Link to="/apply/terms" className="red-link">
                terms and conditions
              </Link>{' '}
              and{' '}
              <Link to="/web/privacy" className="red-link">
                privacy policy.
              </Link>
            </span>
          </label>
        </div> */}

          <button
            type="button"
            className="submit c-btn c-btn--red"
            onClick={handleNextStage}
            disabled={hasErrorsOnFirstStage || hasErrorsOnSecondStage || isSubmiting || isLoading}
          >
            {isLoading ? <i className="spinner" /> : 'Pay now'}
          </button>
          {/* <button
            type="submit"
            className="submit c-btn c-btn--red mt-4"
            disabled={hasErrorsOnFirstStage || hasErrorsOnSecondStage || isSubmiting || isLoading}
          >
            {isSubmiting ? <i className="spinner" /> : 'Submit application'}
          </button> */}
          {/* {isSuccess && (
            <div className="success-msg">
              <span> Your application has been successfully submitted!</span>
            </div>
          )} */}

          {isError && (
            <div className="error-msg">
              <span>{isError}</span>
            </div>
          )}
        </div>
      )}
      {stage === stages.PAYMENT && (
        <div className="form">
          <BackButton onClick={handlePrevStage} />
          {stripe && clientSecret && (
            <Elements stripe={stripe} options={{ clientSecret }}>
              <PaymentForm appliedDeposit={paymentType.appliedDeposit} values={values} />
            </Elements>
          )}
        </div>
      )}
    </form>
  );
}

const PaymentForm = ({ values, appliedDeposit }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const stripeHook = useStripe();
  const elements = useElements();

  const submitPayment = async () => {
    if (!stripeHook || !elements) {
      return;
    }
    setIsProcessing(true);
    try {
      // write confirmPayment without rediretion
      const { error, paymentIntent } = await stripeHook.confirmPayment({
        elements,
        confirmParams: {},
        redirect: 'if_required',
      });
      console.log('paymentIntent: ', paymentIntent);

      if (error) {
        setErrorMsg(error.message);
      }

      if (paymentIntent.status === 'succeeded') {
        setIsSuccess(true);

        const leadItemId = localStorage.getItem('leadItemId');

        if (!leadItemId) return;
        
        try {
          const response = await fetch('/api/crm-update-lead-status', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ itemId: leadItemId, appliedDeposit }),
          });
          const jsonResponse = await response.json();
          if (jsonResponse?.success && !jsonResponse?.data?.error_code) {
            navigate('/apply/thank-you');
            setIsSuccess(true);
          }
        } catch (error) {
          console.log('error: ', error);
        }
      }
    } catch (error) {
      setErrorMsg(error.message);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <>
      <PaymentElement />
      <button
        disabled={isProcessing}
        type="button"
        className="submit c-btn c-btn--red mt-4"
        onClick={submitPayment}
      >
        {isProcessing ? <i className="spinner" /> : 'Pay now'}
      </button>
      {isSuccess && (
        <div className="success-msg">
          <span>Your application has been successfully submitted!</span>
        </div>
      )}
      {errorMsg && (
        <div className="error-msg">
          <span>{errorMsg}</span>
        </div>
      )}
    </>
  );
};

const AccommodationDuration = ({
  accommodation,
  handleChange,
  errors,
  touched,
  accommodationDuration,
}) => {
  if (!accommodation.length || accommodation === 'No') return null;

  return (
    <div>
      <h3 className="form__subtitle start-date">Duration </h3>
      {accommodation === 'Piso compartido' && (
        <>
          {' '}
          <div className="form__radio">
            <input
              id="course-radio-accommodation-1"
              type="radio"
              name="accommodationDuration"
              checked={accommodationDuration === '1 mes'}
              value="1 mes"
              onChange={handleChange}
            />
            <label htmlFor="course-radio-accommodation-1">
              <span className="label">1 month 750€</span>
            </label>
          </div>
          <div className="form__radio">
            <input
              id="course-radio-accommodation-2"
              type="radio"
              name="accommodationDuration"
              checked={accommodationDuration === '2 meses'}
              value="2 meses"
              onChange={handleChange}
            />
            <label htmlFor="course-radio-accommodation-2">
              <span className="label">2 months 1500€</span>
            </label>
          </div>
          <div className="form__radio">
            <input
              id="course-radio-accommodation-3"
              type="radio"
              name="accommodationDuration"
              checked={accommodationDuration === '3 meses'}
              value="3 meses"
              onChange={handleChange}
            />
            <label htmlFor="course-radio-accommodation-3">
              <span className="label">3 months 2250€</span>
            </label>
          </div>
        </>
      )}
      {accommodation === 'Residencia' && (
        <>
          <div className="form__radio">
            <input
              id="course-radio-accommodation-4"
              type="radio"
              name="accommodationDuration"
              checked={accommodationDuration === '1 mes'}
              value="1 mes"
              onChange={handleChange}
            />
            <label htmlFor="course-radio-accommodation-4">
              <span className="label">1 month 1000€</span>
            </label>
          </div>
          <div className="form__radio">
            <input
              id="course-radio-accommodation-5"
              type="radio"
              checked={accommodationDuration === '2 meses'}
              name="accommodationDuration"
              value="2 meses"
              onChange={handleChange}
            />
            <label htmlFor="course-radio-accommodation-5">
              <span className="label">2 months 2000€</span>
            </label>
          </div>
          <div className="form__radio">
            <input
              id="course-radio-accommodation-6"
              type="radio"
              name="accommodationDuration"
              checked={accommodationDuration === '3 meses'}
              value="3 meses"
              onChange={handleChange}
            />
            <label htmlFor="course-radio-accommodation-6">
              <span className="label">3 months 3000€</span>
            </label>
          </div>
        </>
      )}
      <FieldValidationErrorMsg
        field={errors.accommodationDuration && touched.accommodationDuration}
      />
    </div>
  );
};

const BackButton = ({ onClick, ...restProps }) => {
  return (
    <button onClick={onClick} className="btn-go-back" {...restProps}>
      <IconArrowRight className="go-back-arrow" /> Go back
    </button>
  );
};
export default ApplyFormComponent;
