import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useRef, useState } from 'react';
import { graphql, navigate } from 'gatsby';
// import { useForm, Controller } from 'react-hook-form';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import countries, { languages } from 'countries-list';
import format from 'date-fns/format';
import addToMailchimp from 'gatsby-plugin-mailchimp';

import Layout from '../../components/Layout';
// import FieldValidationErrorMsg from '../../components/FieldValidationErrorMsg';
import ApplyFormComponent from '../../components/ApplyFormComponent';
import { getDatesForDaysCountBeforehend } from '../../utils/common';

import { stages } from '../../constants/stages';

import '../../assets/styles/pages/apply-form-page.scss';
import {
  COURSES_DURATION_AND_PRICES,
  COURSE_FEE,
  FIRST_SET_OF_BOOKS,
  PAYMENT_BY_INSULMENTS_VALUE,
} from '../../utils/constants';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const countryCodes = Object.keys(countries.countries);
const countryNames = countryCodes.map((code) => countries.countries[code].name);
const countryNamesOptions = countryNames.sort().map((country) => ({
  value: country,
  label: country,
}));

// const FieldValidationErrorMsg = ({ field }) =>
//   field ? <div className="error-val">{field}</div> : null;

const initialValues = {
  course: 'Select course...',
  level: 'Select level...',
  startDate: 'Select start date...',
  courseDuration: 'Select course duration...',
  accommodation: '',
  accommodationDuration: '',
  airportPickUp: '',

  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  userLocation: '',
  // nationality: '',
  // dateOfBirth: '',
  userDobDD: '',
  userDobMM: '',
  userDobYYYY: '',
  gender: '', // "male" | "female" | "would rather not say"
  preferredContactLanguage: '', // "english" | "spanish"
  emergencyContactName: '',
  emergencyContactPhone: '',
  comments: '',
};

const firstStageFields = [
  'course',
  'level',
  'startDate',
  'accommodation',
  'accommodationDuration',
  'courseDuration',
  'airportPickUp',
];

const secondStageFields = [
  'firstName',
  'lastName',
  'email',
  'phone',
  'userLocation',
  // 'nationality',
  'userDobDD',
  'userDobMM',
  'userDobYYYY',
  'gender',
  'preferredContactLanguage',
];

const CourseSchema = Yup.object().shape({
  course: Yup.string().required('Required').notOneOf([initialValues.course]),
  level: Yup.string().required('Required').notOneOf([initialValues.level]),
  startDate: Yup.string().required('Required').notOneOf([initialValues.startDate]),
  courseDuration: Yup.string().required('Required').notOneOf([initialValues.courseDuration]),
  accommodationDuration: Yup.string().required('Required'),
  accommodation: Yup.string().required('Required'),
  airportPickUp: Yup.string().required('Required'),

  firstName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  phone: Yup.string()
    .matches(/^\d{10,15}/)
    .required('Required'),
  userLocation: Yup.string().required('Required'),
  // nationality: Yup.string().required('Required'),
  userDobDD: Yup.string()
    .matches(/^[0-9]{1,2}$/)
    .required('Required'),
  userDobMM: Yup.string()
    .matches(/^[0-9]{1,2}$/)
    .required('Required'),
  userDobYYYY: Yup.string()
    .matches(/^[0-9]{4}$/)
    .required('Required'),
  gender: Yup.string().required('Required'),
  preferredContactLanguage: Yup.string().required('Required'),
  emergencyContactName: Yup.string(),
  emergencyContactPhone: Yup.string(),
  comments: Yup.string(),
});

const levelsOptions = [
  {
    value: 'A1.1',
    label: 'A1.1 Beginner',
  },
  {
    value: 'A1.2',
    label: 'A1.2 Beginner 2',
  },
  {
    value: 'A2.1',
    label: 'A2.1 Pre-intermediate 1',
  },
  {
    value: 'A2.2',
    label: 'A2.2 Pre-intermediate 2',
  },
  {
    value: 'B1.1',
    label: 'B1.1 Intermediate 1',
  },
  {
    value: 'B1.2',
    label: 'B1.2 Intermediate 2',
  },
  {
    value: 'B2.1',
    label: 'B2.1 Upper Intermediate 1',
  },
  {
    value: 'B2.2',
    label: 'B2.2 Upper Intermediate 2',
  },
  {
    value: 'C1.1',
    label: 'C1.1 Proficient 1',
  },
  {
    value: 'C1.2',
    label: 'C1.2 Proficient 2',
  },
];

const coursesOptions = [
  {
    value: 'Part-Time 4',
    label: 'Part-Time 4 hours/week',
  },
  {
    value: 'Semi-intensive 10',
    label: 'Semi-intensive 10 hours/week',
  },
  {
    value: 'Intensive 20',
    label: 'Intensive 20 hours/week',
  },
  {
    value: 'Long-term 20 hours & Visa Service',
    label: 'Long-term 20 hours & Visa Service',
  },
  {
    value: 'Conversation Class',
    label: 'Conversation Class',
  },
  {
    value: 'DELE Preparation',
    label: 'DELE Preparation',
  },
  {
    value: 'Private Classes',
    label: 'Private Classes',
  },
];

const stripe = loadStripe(
  process.env.STRIPE_PUBLIC_KEY ||
    'pk_test_51P9RQkB3IOZQAYA5CSPgc9kbsySjHJ2xefsLK9ADT0OKUbajqYCYII8RD4ErBsbKD4nXfZZR4unIOmyHTbwMnYHI006NdhHkNZ'
);

const ApplyForm = ({ data }) => {
  const firstRenderRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isError, setError] = useState(null);
  const [stage, setStage] = useState(stages.COURSE); // "course" | "about" | "summary"
  const [paymentType, setPaymentType] = useState({
    appliedDeposit: false,
  });
  const {
    handleChange,
    handleSubmit,
    setTouched,
    setFieldValue,
    setFieldError,
    values,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema: CourseSchema,
    onSubmit: async (values) => {
      setIsSubmiting(true);
      try {
        const startDateDD = Number(values.startDate.split('/')[0]);
        const startDateMM = Number(values.startDate.split('/')[1]);
        const startDateYYYY = Number(values.startDate.split('/')[2]);
        const MailchimpData = {
          FNAME: values.firstName,
          LNAME: values.lastName,
          PHONE: values.phone,
          MMERGE3: values.course,
          MMERGE5: values.level,
          MMERGE6: format(new Date(startDateYYYY, startDateMM, startDateDD), 'MMMM do YYY'), // start date
          MMERGE7: values.courseDuration,
          MMERGE8: values.accommodation,
          MMERGE9: values.accommodationDuration,
          MMERGE10: values.airportPickUp,
          MMERGE11: values.userLocation,
          // MMERGE12: values.nationality,
          MMERGE13: format(
            new Date(values.userDobYYYY, values.userDobMM - 1, values.userDobDD),
            'MMMM do YYY'
          ),
          MMERGE14:
            values.gender === 'Prefiere no decir'
              ? 'Rather not say'
              : values.gender === 'Hombre'
              ? 'Male'
              : 'Female',
          MMERGE15: values.preferredContactLanguage === 'Inglés' ? 'English' : 'Spanish',
          MMERGE16: values.emergencyContactName,
          MMERGE17: values.emergencyContactPhone,
          MMERGE18: values.comments,
          MMERGE19: 'Application form',
        };
        const response = await fetch('/api/crm', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            values: {
              ...values,
              phone: values.phone,
              status: 'Nuevo lead',
            },
            appliedDeposit: paymentType.appliedDeposit,
            userFromGoogleAds: window?.userFromGoogleAds,
            userFromFacebookAds: window?.userFromFacebookAds,
          }),
        });
        const jsonResponse = await response.json();
        console.log('jsonResponse: ', jsonResponse);
        if (jsonResponse?.success && !jsonResponse?.data?.error_code) {
          setError(false);
          // window.fbq('track', 'SubmitApplication');
          addToMailchimp(values.email, MailchimpData);
          localStorage.setItem('leadItemId', jsonResponse.data.data.create_item.id);
          // navigate('/apply/thank-you');
          setIsSuccess(true);
          handleNextStage();
        } else {
          throw new Error(jsonResponse.error);
        }
      } catch (error) {
        console.error(error);
        setIsSuccess(false);
        setError(error ? error.message || error : 'Oops, something went wrong');
      } finally {
        setIsSubmiting(false);
      }
    },
  });

  useEffect(() => {
    localStorage.setItem('appliedDeposit', paymentType.appliedDeposit);
  }, [paymentType]);

  const applyDeposit = () => {
    setPaymentType({ appliedDeposit: true });
  };
  const applyFullPayment = () => {
    setPaymentType({ appliedDeposit: false });
  };

  const getAirportPikupTaxiPrice = () => {
    if (values.airportPickUp === 'Si') {
      return 70;
    }

    return 0;
  };

  const getAccommodationPrice = () => {
    if (values.accommodation === 'Residencia') {
      if (values.accommodationDuration === '1 mes') {
        return 1000;
      } else if (values.accommodationDuration === '2 meses') {
        return 2000;
      } else if (values.accommodationDuration === '3 meses') {
        return 3000;
      }
    }
    if (values.accommodation === 'Piso compartido') {
      if (values.accommodationDuration === '1 mes') {
        return 750;
      } else if (values.accommodationDuration === '2 meses') {
        return 1500;
      } else if (values.accommodationDuration === '3 meses') {
        return 2250;
      }
    }
    if (values.accommodation === 'No') return 0;
  };

  const calcTotalPrice = () => {
    if (paymentType.appliedDeposit) return PAYMENT_BY_INSULMENTS_VALUE;

    const total =
      getAirportPikupTaxiPrice() +
        getAccommodationPrice() +
        COURSE_FEE +
        FIRST_SET_OF_BOOKS +
        COURSES_DURATION_AND_PRICES?.[values?.course]?.[values?.courseDuration] ?? 0;

    return Number.isNaN(total) ? 0 : total;
  };

  const totalPrice = calcTotalPrice();

  const handleNextStage = async () => {
    if (stage === stages.COURSE) {
      setStage(stages.ABOUT);
      return;
    }
    if (stage === stages.ABOUT) {
      setStage(stages.SUMMARY);
      return;
    }
    if (stage === stages.SUMMARY) {
      const accommodationPrice = getAccommodationPrice();
      const airportPickUpTaxiPrice = getAirportPikupTaxiPrice();

      const stripeBody = {
        products: [
          {
            name: values.course,
            price: COURSES_DURATION_AND_PRICES[values.course][values.courseDuration],
            quantity: 1,
          },
          { name: 'Accommodation', price: accommodationPrice, quantity: 1 },
          { name: 'Airport taxi', price: airportPickUpTaxiPrice, quantity: 1 },
          { name: 'Course fee', price: COURSE_FEE, quantity: 1 },
          { name: '1st set of books', price: FIRST_SET_OF_BOOKS, quantity: 1 },
        ],
      };
      setIsLoading(true);
      const sessionResponse = await fetch('/api/create-checkout-stripe-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          stripeBody,
          userEmail: values.email,
          appliedDeposit: paymentType.appliedDeposit,
        }),
      });
      const sessionData = await sessionResponse.json();

      setIsLoading(false);
      window.open(sessionData.url, '_self');
      // setClientSectet(session.clientSecret);
      // setStage(stages.PAYMENT);
      return;
    }
  };

  useEffect(() => {
    if (firstRenderRef.current === false) {
      window.scrollTo({ top: 550 });
    }
    return () => (firstRenderRef.current = false);
  }, [stage]);

  const handlePrevStage = () => {
    if (stage === stages.ABOUT) {
      setStage(stages.COURSE);
      return;
    }
    if (stage === stages.SUMMARY) {
      setStage(stages.ABOUT);
      return;
    }
    if (stage === stages.PAYMENT) {
      setStage(stages.SUMMARY);
      return;
    }
  };

  const startDateOptions = getDatesForDaysCountBeforehend(52);
  const courseDurationOptions = Object.keys(COURSES_DURATION_AND_PRICES[values.course] ?? {}).map(
    (key) => ({
      value: key,
      label: key,
    })
  );

  const hasErrorsOnFirstStage =
    Object.keys(errors).some((key) => firstStageFields.includes(key)) ||
    values.course === initialValues.course;
  const hasErrorsOnSecondStage = Object.keys(errors).some((key) => secondStageFields.includes(key));

  const firstStageActiveDisc =
    stage === stages.COURSE || stage === stages.ABOUT || stage === stages.SUMMARY;
  const secondStageActiveDisc = stage === stages.ABOUT || stage === stages.SUMMARY;
  const thirdStageActiveDisc = stage === stages.SUMMARY;
  return (
    <Layout
      title={'Book your Spanish course at Iberia Language Academy online'}
      description={
        "Keen to study Spanish at Iberia Language Academy? Complete our simple, non-committal booking form and we'll get back to you In under 12 Hours."
      }
    >
      <div className="apply-form-page">
        <section className="c-first-section c-first-section--md">
          <div className="container">
            <h1 className="title">
              Book your Spanish course today and take the first step towards Spanish fluency
            </h1>
            <div className="wrapper">
              <div className="group">
                <div className={`${firstStageActiveDisc ? 'active' : ''} group__disc`}>
                  <span>1</span>
                </div>
                <div className="group__text">
                  {/* <p className="text__title">1</p> */}
                  <p className="text__description">Selection</p>
                </div>
              </div>

              <div className="group">
                <div className={`${secondStageActiveDisc ? 'active' : ''} group__disc`}>
                  <span>2</span>
                </div>
                <div className="group__text">
                  {/* <p className="text__title">2</p> */}
                  <p className="text__description">Personal details</p>
                </div>
              </div>

              <div className="group">
                <div className={`${thirdStageActiveDisc ? 'active' : ''} group__disc`}>
                  <span>3</span>
                </div>
                <div className="group__text">
                  {/* <p className="text__title">3</p> */}
                  <p className="text__description">Summary & Price</p>
                </div>
              </div>

              <div className="group">
                <div className="group__disc">
                  <span>4</span>
                </div>
                <div className="group__text">
                  {/* <p className="text__title">4</p> */}
                  <p className="text__description">Payment</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="second-section">
          <div className="container">
            <ApplyFormComponent
              stripe={stripe}
              hasErrorsOnFirstStage={hasErrorsOnFirstStage}
              hasErrorsOnSecondStage={hasErrorsOnSecondStage}
              isLoading={isLoading}
              isSuccess={isSuccess}
              isSubmiting={isSubmiting}
              isError={isError}
              handleNextStage={handleNextStage}
              handlePrevStage={handlePrevStage}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              values={values}
              touched={touched}
              setTouched={setTouched}
              courseDurationOptions={courseDurationOptions}
              startDateOptions={startDateOptions}
              levelsOptions={levelsOptions}
              coursesOptions={coursesOptions}
              handleSubmit={handleSubmit}
              stage={stage}
              errors={errors}
              setFieldError={setFieldError}
              initialValues={initialValues}
              countryNamesOptions={countryNamesOptions}
              airportPickUpPrice={getAirportPikupTaxiPrice()}
              accommodationPrice={getAccommodationPrice()}
              totalPrice={totalPrice}
              paymentType={paymentType}
              applyDeposit={applyDeposit}
              applyFullPayment={applyFullPayment}
            />
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allPrismicCourses(sort: { order: ASC, fields: data___from }) {
      edges {
        node {
          data {
            show_on_these_pages {
              page
            }
            from(formatString: "D MMMM YYYY")
            to(formatString: "D MMMM YYYY")
            name {
              text
            }
            location
          }
        }
      }
    }
  }
`;

export default ApplyForm;
